import { CanMatchFn } from '@angular/router';
import { inject } from '@angular/core';
import { FeatureFlagsService } from '@client-portal/services/feature-flags';
import { FeatureFlags } from '@client-portal/models/feature-flag';

/*
 * Guard that checks if a feature flag is enabled or disabled and then conditionally prevents the route from being matched.
 * @param feature - the feature flag key from environment to check.
 * @param mode - 'hide' or 'show' the feature flag - it depends on a convention,
 * if the feature flag is true, it will hide the feature, if it is false, it will show the feature.
 * It's defaulted to 'hide' as all feature flags in our environment are named as 'hide<featureName>'.
 * */
export const featureToggleGuard =
  (feature: keyof FeatureFlags, mode: 'hide' | 'show' = 'hide'): CanMatchFn =>
  () => {
    const featureFlags = inject(FeatureFlagsService);
    const featureValue = featureFlags.values[feature]();

    if (typeof featureValue === 'boolean') {
      switch (mode) {
        case 'hide':
          return !featureValue;
        case 'show':
          return featureValue;
      }
    }
    return true;
  };
