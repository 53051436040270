import { Routes } from '@angular/router';
import {
  authGuard,
  emailVerificationGuard,
  featureToggleGuard,
  inactiveAccountGuard,
} from '@client-portal/auth/guards';
import { ROUTE_PATHS } from './route-paths';

export const appRoutes: Routes = [
  {
    path: '',
    redirectTo: ROUTE_PATHS.currentOrders.root,
    pathMatch: 'full',
  },
  {
    // parent route for gathering all routes that require common guards
    path: '',
    canActivateChild: [authGuard, emailVerificationGuard, inactiveAccountGuard],
    children: [
      {
        path: ROUTE_PATHS.notifications,
        title: 'Notifications',
        loadComponent: () =>
          import(
            '../features/notifications/components/notifications/notifications.component'
          ).then((c) => c.NotificationsComponent),
        canMatch: [featureToggleGuard('hideNotifications')],
      },
      {
        path: ROUTE_PATHS.currentOrders.root,
        title: 'Current Orders',
        loadChildren: () => import('../features/current-orders/routes'),
      },
      {
        path: ROUTE_PATHS.orderHistory.root,
        title: 'Order History',
        loadChildren: () => import('../features/order-history/routes'),
      },
      {
        path: ROUTE_PATHS.statistics,
        title: 'Statistics',
        loadComponent: () =>
          import(
            '../features/statistics/components/statistics/statistics.component'
          ).then((c) => c.StatisticsComponent),
      },
      {
        path: ROUTE_PATHS.helpAndSupport.root,
        title: 'Help and Support',
        loadChildren: () => import('../features/help-and-support/routes'),
      },
      {
        path: ROUTE_PATHS.profile.root,
        title: 'Profile',
        loadChildren: () => import('../features/profile/routes'),
      },
      {
        path: ROUTE_PATHS.orderTypes,
        title: 'Order types',
        loadComponent: () =>
          import(
            '../features/order-types/components/order-types/order-types.component'
          ).then((c) => c.OrderTypesComponent),
      },
      {
        path: ROUTE_PATHS.administration.root,
        title: 'Administration',
        loadChildren: () => import('../features/administration/routes'),
      },
      {
        path: ROUTE_PATHS.features,
        title: 'Our features',
        loadComponent: () =>
          import(
            '../features/our-features/components/our-features/our-features.component'
          ).then((c) => c.OurFeaturesComponent),
        canMatch: [featureToggleGuard('hideOurFeatures')],
      },
      {
        path: ROUTE_PATHS.login,
        title: 'LoginRedirect',
        loadComponent: () =>
          import(
            '../authentication/components/login-redirect/login-redirect.component'
          ).then((c) => c.LoginRedirectComponent),
      },
      {
        path: ROUTE_PATHS.logout,
        title: 'LogOut',
        loadComponent: () =>
          import('../authentication/components/log-out/log-out.component').then(
            (c) => c.LogOutComponent,
          ),
      },
      {
        path: ROUTE_PATHS.accessDenied,
        title: 'Access Denied',
        loadComponent: () =>
          import(
            '../features/misc/components/access-denied/access-denied.component'
          ).then((c) => c.AccessDeniedComponent),
      },
      {
        path: ROUTE_PATHS.unauthorized,
        title: 'Unauthorized',
        loadComponent: () =>
          import(
            '../features/misc/components/unauthorized/unauthorized.component'
          ).then((c) => c.UnauthorizedComponent),
      },
    ],
  },
  {
    path: ROUTE_PATHS.callback,
    title: 'Callback',
    loadComponent: () =>
      import('../features/misc/components/callback/callback.component').then(
        (c) => c.CallbackComponent,
      ),
  },
  {
    path: ROUTE_PATHS.inactiveAccount,
    title: 'Inactive Account',
    loadComponent: () =>
      import(
        '../features/misc/components/inactive-account/inactive-account.component'
      ).then((c) => c.InactiveAccountComponent),
  },
  {
    path: ROUTE_PATHS.unverifiedEmail,
    title: 'Unverified Email',
    loadComponent: () =>
      import(
        '../features/misc/components/unverified-email/unverified-email.component'
      ).then((c) => c.UnverifiedEmailComponent),
  },
  {
    path: ROUTE_PATHS.error,
    title: 'Error',
    loadComponent: () =>
      import('../features/misc/components/error/error.component').then(
        (c) => c.ErrorComponent,
      ),
  },
  {
    path: '**',
    title: 'Page Not Found',
    loadComponent: () =>
      import(
        '../features/misc/components/page-not-found/page-not-found.component'
      ).then((c) => c.PageNotFoundComponent),
  },
];
